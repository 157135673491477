export const items = {
    stcode: null,
    stname: null,
    stnamedisplay: null,
    stnameEN: null,
    unit: null,
    price: null,
    feature: null,
    typecode: null,
    typename: null,
    supcode: null,
    supname: null,
    procode: null,
    proname: null,
    yield: null,
    multiply: null,
    allergen: null,
    remarks: null,
    purpose: null,
    enumber: null,
    country: null,
    status: null,
}