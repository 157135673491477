import { Layout } from "antd";

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fafafa", height: 64 }}  >
     
    </AntFooter>
  );
}

export default Footer;
